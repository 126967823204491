import { Checkbox, Form, Image, Row, notification } from 'antd';
import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { CustomModal } from '../../../common/components/CustomModal/CustomModal';
import { CustomSelector } from 'src/common/components/CustomSelector/CustomSelector';
import { CustomSpinner } from 'src/common/components/CustomSpinner/CustomSpinner';
import { MilaMessage } from 'src/common/components/MilaMessage/MilaMessage';
import { SharedLink } from './SharedLink/SharedLink';
import { WhiteRow } from 'src/common/components/WhiteRow/WhiteRow';
import { getProfileNameLabel, sortProfilesByItself } from 'src/common/utils';
import { useEffect, useMemo } from 'react';
import { useForm } from 'antd/es/form/Form';
import {
  useGetProfilesQuery,
  useGetSharedMedcardsQuery,
  useShareMedcardMutation,
} from 'src/common/redux/api/apiPatientSlice';
import ShareMedcard from 'src/assets/ShareMedcard.png';
import Typography from 'antd/es/typography/Typography';
import {useGetWhiteLabelInfoQuery} from '../../../common/redux/api/apiWhiteLabelSlice';
import styles from './styles.module.scss';

const howToShareText = (
  <>
    <Typography className={styles.GreyText}>
      Вы можете делиться данными с врачом, к которому записались на прием, или просто создать ссылку и передать ее по
      своему усмотрению.
    </Typography>
    <br />
    <Typography className={styles.GreyText}>
      В обоих вариантах ссылки имеют органиченное время действия. Также в обоих вариантах по передаваемой ссылке будут
      доступны только те полученные из клиник либо загруженные вручную медицинские документы, просмотр которых был
      разрешен вами в настройках документа.
    </Typography>
    <br />
    <Typography className={styles.GreyText}>
      В случае, если вы хотите предоставить доступ врачу, необходимо открыть карточку предстоящей записи и в блоке
      «Информация для врача» нажать кнопку «Поделиться медкартой с врачом». Ссылка для врача будет передана в
      медицинскую информационную систему клиники вместе с вашей записью на прием. Просмотр данных по такой ссылке будет
      открыт в течение 24 часов только в день назначенного приема.
    </Typography>
    <br />
    <Typography className={styles.GreyText}>
      Вы также можете просто создать ссылку передать ее по своему усмотрению - например, родственнику или знакомому
      врачу. Просмотр данных по такой ссылке будет открыт в течение 24 часов с момента ее создания.
    </Typography>
    <br />
    <Typography className={styles.GreyText}>
      По истечении срока действия все ссылки удаляются автоматически. По клику на кнопку «Поделиться медкартой» вы
      можете посмотреть список действующих ссылок на текущий момент и при необходимости закрыть доступ и удалить ссылку
      досрочно.
    </Typography>
    <br />
    <Typography className={styles.GreyText}>
      Важно помнить, что вы самостоятельно несете ответственность за раскрытие персональных данных неопределенному кругу
      лиц по своему усмотрению.
    </Typography>
  </>
);

interface ShareModalProps {
  idProfile?: string;
  appointmenId?: string;
  className?: string;
  isModalOpen: boolean;
  onCancelClick?: () => void;
  hideSelect?: boolean;
}

export const ShareModal = ({
  idProfile,
  appointmenId,
  className,
  isModalOpen,
  onCancelClick,
  hideSelect,
}: ShareModalProps) => {
  return (
    <>
     {isModalOpen  && <ShareModalNotOptimized idProfile={idProfile} appointmenId={appointmenId} className={className} isModalOpen={isModalOpen}
    onCancelClick={onCancelClick} hideSelect={hideSelect} />}
    </>
  );
};

const ShareModalNotOptimized = ({
  idProfile,
  appointmenId,
  className,
  isModalOpen,
  onCancelClick,
  hideSelect,
}: ShareModalProps) => {
  const [form] = useForm();
  const { data: profiles } = useGetProfilesQuery();
  const agreeCheck = Form.useWatch('agreeCheck', form);
  const { data: whiteLabel } = useGetWhiteLabelInfoQuery('');
  const isWhiteLabelEnabled = useMemo(() => {
      return whiteLabel?.isServiceEnabled;
  }, [whiteLabel]);

  const selectProfileOptions = useMemo(
    () =>
      profiles
        ? sortProfilesByItself(profiles!).map((profile) => ({
            value: profile.idProfile,
            label: getProfileNameLabel(profile),
          }))
        : [],
    [profiles],
  );

  const [shareMedcard, result] = useShareMedcardMutation();
  const { data: links, isFetching } = useGetSharedMedcardsQuery();

  const handleSharing = () => {
    const formData = form.getFieldsValue();

    if (appointmenId) {
        shareMedcard({ ...formData, hideSmartAnalysisTab: true, type: 0, idAppointment: appointmenId }).unwrap().catch(error => {
            notification.error({ message: error.data.detail });
        });
    } else {
        shareMedcard({ ...formData, hideSmartAnalysisTab: true }).unwrap().catch(error => {
            notification.error({ message: error.data.detail }); 
        }); 
    }
  };

  useEffect(() => {
    if (result.data) {
      const address =
        window.__RUNTIME_CONFIG__.REACT_APP_HOST.slice(-1) === '/'
          ? window.__RUNTIME_CONFIG__.REACT_APP_HOST.slice(0, -1)
          : window.__RUNTIME_CONFIG__.REACT_APP_HOST;
      navigator.clipboard.writeText(`${address}/shared/medcard/${result.data}`);
      notification.success({ message: 'Ссылка скопирована в буфер обмена' });
    }
  }, [result]);

  useEffect(() => {
    if (idProfile) {
      form.setFieldValue('idProfile', idProfile);
    }
  }, [form, idProfile]);

  return (
    <CustomModal
      isOpen={isModalOpen}
      onCancelClick={onCancelClick}
      className={`${styles.ShareModalContainer} ${className}`}
    >
      <Row className={styles.ShareModalWrapper}>
        <Typography className={styles.Title}>Поделиться медкартой</Typography>
        <Image className={styles.Image} preview={false} src={ShareMedcard} />
        <MilaMessage
          className={styles.MilaMessageContainer}
          title="Как делиться медкартой"
          text="Вы можете делиться данными с врачом, к которому записались на прием, или просто создать ссылку и передать ее по своему усмотрению."
          hiddenText={howToShareText}
        />
        <WhiteRow className={styles.Col}>
          <Form form={form} onFinish={handleSharing} className={styles.FormCol}>
            {/* <Typography className={styles.SubTitle}>Настройка ссылки на просмотр медкарты</Typography> */}
            <Typography className={styles.SubTitle}>Создать ссылку на просмотр медкарты</Typography>
            <Form.Item
              name="idProfile"
              rules={[
                {
                  required: true,
                  message: 'Пожалуйста выберите пациента',
                },
              ]}
              className={styles.Input}
            >
              <CustomSelector floatLabel="Медкарта пользователя" options={selectProfileOptions} disabled={hideSelect} />
            </Form.Item>
            {/* <Row className={styles.CheckBoxRow}>
              <Form.Item name={'hideSmartAnalysisTab'} valuePropName={'checked'} className={styles.CheckBoxItem}>
                <Checkbox />
              </Form.Item>
              <Typography>Не включать раздел “Календарь обращений”</Typography>
            </Row> */}
            <Row>
              <Form.Item
                name={'agreeCheck'}
                valuePropName={'checked'}
                className={styles.CheckBoxItem}
                rules={[
                  {
                    required: true,
                    message: 'Пожалуйста, подтвердите, что согласны',
                  },

                  () => ({
                    validator: (_, value) => (value ? Promise.resolve() : Promise.reject()),
                  }),
                ]}
              >
                <Row className={styles.CheckBoxRow}>
                  <Checkbox />
                  <Typography>
                    Осведомлен и согласен с тем, что разработчик веб-приложения {!isWhiteLabelEnabled ? 'Mila ООО "ЭлНетМед"' : ''} не несет
                    ответственности за раскрытие мной персональных данных при передаче данной ссылки третьим лицам.
                  </Typography>
                </Row>
              </Form.Item>
            </Row>
            <CustomButton isPrimal className={styles.ShareButton} onClick={form.submit} disabled={!agreeCheck}>
              <Typography>Создать и скопировать ссылку</Typography>
            </CustomButton>
          </Form>
        </WhiteRow>

        <WhiteRow className={styles.Col}>
          <Typography className={styles.SubTitle}>Действующие ссылки на просмотр медкарты</Typography>
          {isFetching ? (
            <CustomSpinner />
          ) : links?.length !== 0 ? (
            links?.map((link) => <SharedLink link={link} key={link.idMilaSharedLink} />)
          ) : (
            <Typography>Действующих ссылок нет</Typography>
          )}
        </WhiteRow>
      </Row>
    </CustomModal>
  );
};
