import React, { useEffect, useState } from 'react';
import { Row, Col, Typography } from 'antd';
import { CustomSwitch } from 'src/common/components/CustomSwitch/CustomSwitch';
import { Docs, OkIcon } from 'src/common/icons';
import dayjs from "dayjs";
import { Link } from "react-router-dom";

import styles from './styles.module.scss';

interface SignedAgreementProps {
    id: string;
    createdDate: Date;
    documents: {
        name: string;
        title: string;
        fileUrl: string;
        optional: boolean;
        waiver: boolean;
        patientSignedDate: Date;
        doctorSignedDate: Date;
    }[];
    backToList: () => void;
}

export const SignedAgreement = ({id, documents, createdDate, backToList}: SignedAgreementProps) => {
    const [opened, setOpened] = useState<any[]>([]);
    const [signedDate, setSignedDate] = useState<any>(null);
    
    useEffect(() => {
        setSignedDate(documents?.find(x => !x.waiver)?.patientSignedDate);        
    }, [documents]);
    
    return (
        <Row className={styles.Area}>
            <Typography className={styles.BlockTitle}>Подписанные документы</Typography>
            {signedDate && <Typography className={styles.OkNote}>
                <div className={styles.OkIconContainer}><OkIcon /></div>
                Вы подписали согласие на участие {dayjs(signedDate).format('DD MMMM YYYY в HH:mm')}
            </Typography>}
                {documents?.filter(document => !document.waiver)?.map((document, key) => <Row key={key}
                                                                                             className={styles.Block}>
                    <Col className={styles.Info}>
                        <Docs/>
                        <Row className={styles.Data}>
                            <Link to={document.fileUrl} target="_blank" className={styles.Title}>
                                {document.title}
                            </Link>
                            {document.patientSignedDate && <Typography
                                className={styles.Text}>Подписано пациентом: {dayjs(document.patientSignedDate).format('DD MMMM YYYY в HH:mm')}</Typography>}
                            <Row>
                                <Typography.Text
                                    onClick={() => setOpened(opened.includes(document.name) ? [...opened.filter(x => x !== document.name)] : [...opened, document.name])}
                                    className={`${styles.MessageHeader} ${opened.includes(document.name) ? styles.Open : ''}`}
                                >
                                    История
                                </Typography.Text>
                                <Row className={`${styles.MessageBody} ${opened.includes(document.name) ? styles.Open : ''}`}>
                                    {document.doctorSignedDate && <Typography
                                        className={styles.Text}>Подписано врачом: {dayjs(document.doctorSignedDate).format('DD MMMM YYYY в HH:mm')}</Typography>}
                                    {document.patientSignedDate && <Typography
                                        className={styles.Text}>Подписано пациентом: {dayjs(document.patientSignedDate).format('DD MMMM YYYY в HH:mm')}</Typography>}
                                    {createdDate && <Typography
                                        className={styles.Text}>Получено: {dayjs(createdDate).format('DD MMMM YYYY в HH:mm')}</Typography>}
                                </Row>
                            </Row>
                        </Row>
                    </Col>
                    <CustomSwitch disabled={true} checked={true} text="Подписать" checkedText="Подписано"/>
                </Row>)}
        </Row>
    );
};
