import { Button, Col, DatePicker, Row, Select, Typography } from 'antd';
import { ButtonsBlock } from '../../../common/components/CancelAppointmentModals/ButtonBlock/ButtonsBlock';
import { Calendar, CaretRight, RubleSign } from 'src/common/icons';
import { CustomCardTabs } from 'src/common/components/CustomCardTabs/CustomCardTabs';
import { CustomDateTag } from 'src/common/components/CustomDateTag/CustomDateTag';
import { CustomModal } from '../../../common/components/CustomModal/CustomModal';
import { CustomSpinner } from 'src/common/components/CustomSpinner/CustomSpinner';
import {DateSlot, Doctor, MilaService, ScheduleArgs, ScheduleUI, Service, Slot} from 'src/common/types';
import { MilaMessage } from '../../../common/components/MilaMessage/MilaMessage';
import { RangePickerProps } from 'antd/es/date-picker';
import { SLOT_RANGE } from 'src/common/contants/constatns';
import { ServiceRequestType } from 'src/common/types/enums';
import { mapSchedules } from '../Doctor.mapper';
import {
  resetAppointmentData,
  setAppointmentId,
  setDoctor,
  setMedOrganization,
  setService,
  setServiceRequestType,
  setSlot,
  setSpecialty,
} from 'src/common/redux/appointment/appointmentSlice';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useCancelAppointmentMutation,
  useGetDoctorClinicsServicesQuery,
  useGetDoctorScheduleQuery,
  useRescheduleAppointmentMutation,
} from 'src/common/redux/api/apiPatientSlice';
import {
  useGetWhiteLabelInfoQuery,
} from 'src/common/redux/api/apiWhiteLabelSlice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import styles from './styles.module.scss';

interface DoctorScheduleProps {
  doctor: Doctor;
  className?: string;
  startTime?: Dayjs | null;
  endTime?: Dayjs | null;
  selectedDate?: Dayjs;
  preselectedSpecialtyId?: string;
  preselectedBranchId?: string;
}

export const DoctorSchedule = ({
  doctor,
  className,
  startTime,
  endTime,
  selectedDate,
  preselectedSpecialtyId,
  preselectedBranchId,
}: DoctorScheduleProps) => {
  const [params] = useSearchParams();
  const reschedule = useMemo(() => !!params.get('reschedule'), [params]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const serviceRequestType = useAppSelector((state) => state.appointment.serviceRequestType);
  const rescheduleAppointmentId = useAppSelector((state) => state.appointment.rescheduleAppointmentId);
  const region = useAppSelector((state) => state.filter.region);

  const [activeTab, setActiveTab] = useState(serviceRequestType || ServiceRequestType.OnSite);
  const [specialtyId, setSpecialtyId] = useState<string>();
  const [selectedBranchId, setSelectedBranchId] = useState<string | undefined>();
  const [filteredServices, setFilteredServices] = useState<Service[]>();
  const [selectedService, setSelectedService] = useState<Service | null>();
  const [selectedSchedule, setSelectedSchedule] = useState<ScheduleUI | undefined>();
  const [selectedRange, setRange] = useState<number>(0);
  const [selectedDateRange, setDateRange] = useState<DateSlot[]>();
  const [availableDateSlots, setAvailableDateSlots] = useState<DateSlot[]>();
  const [selectedDateSlot, setDateSlot] = useState<DateSlot>();
  const [selectedTimeSlot, setTimeSlot] = useState<Slot>();

  const [open, setOpen] = useState(false);

  const [isConfirmationRescheduleAppointmentModalVisible, setConfirmationRescheduleAppointmentModalVisibility] =
    useState(false);

  const queryArgs: ScheduleArgs = useMemo(
    () => ({
      idDoctor: doctor.idMilaDoctor || '',
      idFerSpecialization: specialtyId!,
      from: selectedDate ? selectedDate.format() : dayjs().format(),
      to: selectedDate ? selectedDate.add(1, 'days').format() : dayjs().startOf('day').add(2, 'weeks').format(),
      region: region || 'Все регионы',
    }),
    [doctor.idMilaDoctor, specialtyId, selectedDate, region],
  );

  const { data: scheduleData, isFetching } = useGetDoctorScheduleQuery(queryArgs, {
    skip: !doctor.idMilaDoctor || !specialtyId,
  });

  const { data: clinics } = useGetDoctorClinicsServicesQuery(
    { doctorId: doctor.idMilaDoctor, region: region || 'Все регионы' },
    {
      skip: !doctor.idMilaDoctor,
    },
  );
  const { data: whiteLabel } = useGetWhiteLabelInfoQuery('');

  const [rescheduleAppointment, rescheduleMutation] = useRescheduleAppointmentMutation();
  const [cancelAppointment, cancelMutation] = useCancelAppointmentMutation();

  const filteredTimeSlots = useMemo(() => {
    let tempSlots =
      selectedDateSlot?.timeSlots.filter(
        (slot) =>
          !slot.specialityServices || slot.specialityServices.includes(selectedService?.idSpecialtyService ?? ''),
      ) ?? [];
    if (startTime) {
      tempSlots = tempSlots.filter(
        (slot) =>
          dayjs(slot.visitStart).diff(dayjs(slot.visitStart).startOf('d'), 'm') >=
          startTime.diff(startTime.startOf('d'), 'm'),
      );
    }
    if (endTime) {
      tempSlots = tempSlots.filter(
        (slot) =>
          dayjs(slot.visitStart).diff(dayjs(slot.visitStart).startOf('d'), 'm') <=
          endTime.diff(endTime.startOf('d'), 'm'),
      );
    }
    return tempSlots;
  }, [selectedDateSlot?.timeSlots, startTime, endTime]);

  const selectedTimeSlots = useMemo(
    () => filteredTimeSlots?.slice(SLOT_RANGE * selectedRange, SLOT_RANGE * (selectedRange + 1)),
    [filteredTimeSlots, selectedRange],
  );

  const doctorServiceTypes = useMemo(() => {
    let doctorServiceTypes: ServiceRequestType[] = [];
    clinics?.forEach((clinic) =>
      clinic.services.forEach((service) => {
        doctorServiceTypes.push(service.serviceDeliveryType);
      }),
    );
    return [...new Set(doctorServiceTypes)];
  }, [clinics]);

  let onlineServices = clinics?.find((clinic) => clinic.idMedOrganization === selectedBranchId)?.services || [];
  if (onlineServices.length) {
    if (preselectedSpecialtyId) {
      onlineServices = onlineServices.filter((service) => service.idFedSpecialty === preselectedSpecialtyId);
    }
    onlineServices.filter((service) => service.serviceDeliveryType === ServiceRequestType.Online);
  }

  const items = useMemo(
    () => [
      {
        label: `В клинике`,
        key: ServiceRequestType.OnSite as unknown as string,
        disabled: !doctorServiceTypes?.includes(ServiceRequestType.OnSite) || (whiteLabel?.isServiceEnabled && !whiteLabel?.services?.includes(MilaService.OnSite)),
      },
      {
        label: `Онлайн`,
        key: ServiceRequestType.Online as unknown as string,
        disabled: !doctorServiceTypes?.includes(ServiceRequestType.Online) || onlineServices.length === 0 || (whiteLabel?.isServiceEnabled && !whiteLabel?.services?.includes(MilaService.Online)),
      },
      {
        label: `На дому`,
        key: ServiceRequestType.AtHome as unknown as string,
        disabled: !doctorServiceTypes?.includes(ServiceRequestType.AtHome) || (whiteLabel?.isServiceEnabled && !whiteLabel?.services?.includes(MilaService.AtHome)),
      },
    ],
    [doctorServiceTypes, onlineServices],
  );

  useEffect(() => {
    if (serviceRequestType !== undefined && doctorServiceTypes.includes(serviceRequestType)) {
      setActiveTab(serviceRequestType);
    } else {
      setActiveTab(doctorServiceTypes[0]);
    }
  }, [doctorServiceTypes, serviceRequestType]);

  useEffect(() => {
    const filteredClinics = getClinics();
    if (preselectedBranchId) {
      setSelectedBranchId(preselectedBranchId);
    } else if (filteredClinics && filteredClinics?.length > 0) {
      setSelectedBranchId(filteredClinics[0].idMedOrganization);
    }
  }, [clinics, preselectedBranchId, activeTab]);

  useEffect(() => {
    const serviceId = selectedService?.idSpecialtyService ?? '';
    if (selectedSchedule?.slots) {
      const schedulesWithSlots = selectedSchedule?.slots.filter((slots: DateSlot) => {
        return slots.timeSlots.some((ts) => !ts.specialityServices || ts.specialityServices?.includes(serviceId));
      });

      setAvailableDateSlots(schedulesWithSlots);
      setDateRange(schedulesWithSlots.slice(0, 2));
    } else {
      setAvailableDateSlots([]);
      setDateRange([]);
    }
  }, [selectedSchedule, selectedService]);

  useEffect(() => {
    if (availableDateSlots?.length) {
      setDateSlot(availableDateSlots[0]);
    } else {
      setDateSlot(undefined);
    }
  }, [availableDateSlots]);

  useEffect(() => {
    if (preselectedSpecialtyId) {
      setSpecialtyId(preselectedSpecialtyId);
    }
    if (selectedService) {
      setSpecialtyId(selectedService.idFedSpecialty);
    }
  }, [preselectedSpecialtyId, selectedService]);

  useEffect(() => {
    if (scheduleData) {
      if (selectedBranchId) {
        const schedules = mapSchedules(scheduleData);
        const newSchedule = schedules.find((schedule) => schedule.idMedOrganization === selectedBranchId);

        newSchedule?.slots.forEach((slot, slotId) => {
          const featureVisits: Slot[] = slot.timeSlots.filter((timeSlot: Slot) => {
            return dayjs(timeSlot.visitStart).diff(new Date()) > 300000;
          });
          newSchedule.slots[slotId].timeSlots = [...featureVisits];

          return featureVisits.length > 0;
        });

        if (newSchedule) {
          setSelectedSchedule(newSchedule);
        }
      }
    }
  }, [scheduleData, selectedBranchId]);

  useEffect(() => {
    if (clinics && selectedBranchId) {
      let specialtyServices = clinics?.find((clinic) => clinic.idMedOrganization === selectedBranchId)?.services;
      if (specialtyServices) {
        if (preselectedSpecialtyId) {
          specialtyServices = specialtyServices.filter((service) => service.idFedSpecialty === preselectedSpecialtyId);
        }
        specialtyServices = specialtyServices.filter((service) => service.serviceDeliveryType === activeTab);
        specialtyServices.sort((a: Service, b: Service) => {
          if (a.serviceName < b.serviceName) {
            return -1;
          }
          if (a.serviceName > b.serviceName) {
            return 1;
          }
          return 0;
        });

        setFilteredServices(specialtyServices);
        if (specialtyServices?.length === 0) {
          setAvailableDateSlots([]);
          setDateRange([]);
        }
      } else {
        setFilteredServices([]);
        setAvailableDateSlots([]);
        setDateRange([]);
      }
    } else {
      setFilteredServices([]);
      setAvailableDateSlots([]);
      setDateRange([]);
    }
  }, [selectedBranchId, clinics, activeTab, preselectedSpecialtyId]);

  useEffect(() => {
    if (filteredServices && filteredServices.length > 0) {
      setSelectedService(filteredServices[0]);
    }
  }, [filteredServices]);

  const chooseSlot = useCallback(
    (slot?: Slot) => {
      dispatch(resetAppointmentData());
      if (rescheduleAppointmentId && reschedule) {
        dispatch(setAppointmentId(rescheduleAppointmentId));
      }
      dispatch(setDoctor(doctor));
      if (slot) {
        dispatch(setSlot(slot));
      }
      const service = filteredServices?.find(
        (service) => service.idSpecialtyService === selectedService?.idSpecialtyService,
      );
      if (service) {
        dispatch(setService(service));
        dispatch(setSpecialty(service.idFedSpecialty));
      }
      dispatch(setMedOrganization(clinics?.find((clinic) => clinic.idMedOrganization === selectedBranchId)!));
      dispatch(setServiceRequestType(activeTab));
      if (rescheduleAppointmentId) {
        navigate(`/records/appointmentResult/${rescheduleAppointmentId}?isSuccess=true`);
      } else {
        navigate(`/records/patientChoice`);
      }
    },
    [
      activeTab,
      clinics,
      dispatch,
      doctor,
      filteredServices,
      navigate,
      reschedule,
      rescheduleAppointmentId,
      selectedBranchId,
      selectedService?.idSpecialtyService,
    ],
  );

  const handleTimeSelect = (slot?: Slot) => {
    if (reschedule) {
      setTimeSlot(slot);
      setConfirmationRescheduleAppointmentModalVisibility(true);
    } else {
      chooseSlot(slot);
    }
  };

  const handleDateSelect = (dateSlot: DateSlot) => {
    setDateSlot(dateSlot);
    setRange(0);
  };

  const handleRangeSelect = (value: number) => {
    setRange(value);
  };

  const handleServiceChange = (serviceId: string) => {
    setAvailableDateSlots([]);
    setDateRange([]);
    setSelectedService(filteredServices?.find((service) => service.idSpecialtyService === serviceId));
    setRange(0);
  };

  const handleCalendarDateSelect = (arg: Dayjs | null) => {
    if (arg) {
      const neededDateSlot = availableDateSlots?.find(
        (slot) => slot.dateSlot.startOf('d').diff(arg.startOf('d'), 'd') === 0,
      );
      setDateSlot(neededDateSlot);
      if (neededDateSlot) {
        setDateRange([neededDateSlot]);
      }
      setRange(0);
    }
  };

  const handleBranchChange = (branchId: string) => {
    setSelectedBranchId(branchId);
    setRange(0);
  };

  const handleTabChange = (tab: string) => {
    setActiveTab(tab as unknown as ServiceRequestType);
  };

  const dateRange = useMemo(() => {
    let slots = availableDateSlots;

    if (activeTab === ServiceRequestType.Online) {
      slots = slots?.filter((slot) => {
        const slotTime = slot.dateSlot.startOf('d');
        return slotTime && slotTime >= dayjs().startOf('day') && slotTime <= dayjs().add(6, 'days').endOf('day');
      });
    }

    return slots?.map((slot) => slot.dateSlot.startOf('d'));
  }, [availableDateSlots, activeTab]);

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return dateRange ? !dateRange.some((val) => val.diff(current.startOf('d'), 'd') === 0) : true;
  };

  const closeRescheduleModal = () => {
    setConfirmationRescheduleAppointmentModalVisibility(false);
  };

  const onConfirmRescheduleClick = async () => {
    if (rescheduleAppointmentId) {
      await cancelAppointment({ appointmentId: rescheduleAppointmentId, isReschedule: true });
      await rescheduleAppointment({
        appointmentId: rescheduleAppointmentId,
        endDate: selectedTimeSlot?.visitEnd!,
        startDate: selectedTimeSlot?.visitStart!,
        misTimeSlotId: selectedTimeSlot?.idAppointment!,
      });
      chooseSlot(selectedTimeSlot);
    }
  };

  const formClinicAddressText = (nearestSubwayDescription: string, address: string) => {
    let resAddress = "";

    if (nearestSubwayDescription && nearestSubwayDescription !== "()" && nearestSubwayDescription !== " ()") {
      resAddress = `${nearestSubwayDescription} `;
    };

    if (address) {
      resAddress = `${resAddress}(${address})`;
    };

    return resAddress;
  };

  const getClinics = () => {
    return clinics?.filter((clinic) => clinic.services?.filter(service => 
            service.serviceDeliveryType === activeTab &&
            (!preselectedSpecialtyId || service.idFedSpecialty === preselectedSpecialtyId))?.length > 0);
  };

  return (
      !whiteLabel?.isServiceEnabled || whiteLabel?.services?.includes(MilaService.OnSite) ||
      whiteLabel?.services?.includes(MilaService.Online) || whiteLabel?.services?.includes(MilaService.AtHome)? <Col className={`${styles.ScheduleWrapper} ${className}`}>
      <CustomCardTabs
        className={styles.Tabs}
        items={items}
        containerClassName={styles.TabsContainer}
        onChange={handleTabChange}
        activeKey={activeTab as unknown as string}
      />
      <CustomModal
        isOpen={isConfirmationRescheduleAppointmentModalVisible}
        className={styles.ModalContainer}
        onCancelClick={closeRescheduleModal}
      >
        <Row className={styles.CancelRecordWrapper}>
          <Row className={styles.MilaMessageWrapper}>
            <MilaMessage
              title={'Переносим запись на новое время?'}
              text={
                'Данные о пациенте, информация и доступы для врача будут перенесены в новую запись, старая будет удалена. Если вы оплачивали консультацию онлайн - деньги будут возвращены на вашу карту, а новую запись потребуется оплатить заново.'
              }
            />
          </Row>
          {rescheduleMutation.isLoading || cancelMutation.isLoading ? (
            <CustomSpinner />
          ) : (
            <ButtonsBlock
              buttonOneText="Да, перенести"
              buttonOneOnClick={onConfirmRescheduleClick}
              buttonTwoText="Отмена"
              buttonTwoOnClick={closeRescheduleModal}
            />
          )}
        </Row>
      </CustomModal>
      {filteredServices && filteredServices.length > 0 && (
        <>
          <Select
            className={styles.Select}
            value={selectedBranchId}
            onChange={handleBranchChange}
            disabled={!!preselectedBranchId}
          >
            {getClinics()?.map((clinic) => (
              <Select.Option value={clinic.idMedOrganization} key={clinic.idMedOrganization}>
                <Row className={styles.OptionRow}>
                  <Typography>{clinic.shortName}</Typography>
                  <Typography className={styles.OptionAddress}>
                    {formClinicAddressText(clinic?.nearestSubwayDescription, clinic?.address)}
                  </Typography>
                </Row>
              </Select.Option>
            ))}
          </Select>
          <Select className={styles.Select} onChange={handleServiceChange} value={selectedService?.idSpecialtyService}>
            {filteredServices?.map((service) => (
              <Select.Option
                className={styles.Option}
                value={service.idSpecialtyService}
                key={service.idSpecialtyService}
              >
                <Row className={styles.OptionRow}>
                  <Typography>{service.serviceName}</Typography>
                  <Typography className={styles.PriceText}>
                    {service.serviceCostFrom} <RubleSign />
                  </Typography>
                </Row>
              </Select.Option>
            ))}
          </Select>
        </>
      )}
      {isFetching ? (
        <CustomSpinner />
      ) : availableDateSlots && availableDateSlots.length > 0 ? (
        <>
          <Row className={styles.DateRow}>
            {selectedDateRange?.map((slot) => (
              <CustomDateTag
                key={slot.dateSlot.toISOString()}
                onClick={() => handleDateSelect(slot)}
                selected={slot.dateSlot === selectedDateSlot?.dateSlot}
              >
                {slot.dateSlot.format('D MMM dd')}
              </CustomDateTag>
            ))}
            <CustomDateTag onClick={() => setOpen(!open)}>
              <DatePicker
                open={open}
                className={`${styles.HiddenDatePicker} ${Number(selectedDateRange?.length) < 2 ? styles.CentredDatePicker : ''}`}
                onOpenChange={(open) => setOpen(open)}
                onChange={handleCalendarDateSelect}
                disabledDate={disabledDate}
              />
              <Calendar className={styles.Calendar} />
            </CustomDateTag>
          </Row>
          <Row className={styles.TimeRow}>
            {selectedTimeSlots.length > 0 ? (
              selectedTimeSlots?.map((slot) => (
                <CustomDateTag
                  key={slot.idAppointment}
                  onClick={() => handleTimeSelect(slot)}
                  className={styles.TimeTag}
                >
                  {dayjs(slot.visitStart).format('HH:mm')}
                </CustomDateTag>
              ))
            ) : (
              <Typography className={styles.NoSlotsHint}>
                По выбранным фильтрам не нашлось свободных мест для записи. Пожалуйста, попробуйте изменить фильтры.
              </Typography>
            )}
            {filteredTimeSlots && filteredTimeSlots.length > SLOT_RANGE && (
              <Col className={styles.PaginationButtons}>
                <Button
                  className={styles.CaretTag}
                  onClick={() => handleRangeSelect(selectedRange - 1)}
                  disabled={selectedRange === 0}
                >
                  <CaretRight className={styles.CaretLeft} />
                </Button>
                <Button
                  className={styles.CaretTag}
                  onClick={() => handleRangeSelect(selectedRange + 1)}
                  disabled={(selectedRange + 1) * SLOT_RANGE >= filteredTimeSlots.length}
                >
                  <CaretRight />
                </Button>
              </Col>
            )}
          </Row>
        </>
      ) : (
        <Col className={styles.WaitingBlock}>
          <Typography className={styles.WaitingText}>
            Записаться в лист ожидания на приём к специалисту. Администратор согласует с вами дату и время
          </Typography>
          <Button type="primary" className={styles.WaitingButton} onClick={() => handleTimeSelect()}>
            Оставить заявку
          </Button>
        </Col>
      )}
    </Col> : <></>
  );
};
