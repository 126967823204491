import { DateSlot, Schedule, ScheduleUI, Slot } from 'src/common/types';
import dayjs from 'dayjs';

export const mapSchedules = (schedules: Schedule[]): ScheduleUI[] =>
  schedules.map((schedule) => ({
    idMedOrganization: schedule.idMedOrganization ,
    slots: groupSlots(schedule.slots),
  }));

export const groupSlots = (slots: Slot[]) => {
  let groupedSlots: DateSlot[] = [];
  slots.forEach((slot) => {
    const startDate = dayjs(slot.visitStart);
    
    if (groupedSlots.length !== 0 && groupedSlots[groupedSlots.length - 1].dateSlot.isSame(startDate, 'day')) {
      groupedSlots[groupedSlots.length - 1].timeSlots.push(slot);
    } else {
      groupedSlots.push({
        dateSlot: startDate,
        timeSlots: [slot],
      });
    }
  });

  return groupedSlots;
};
