import {
  AppointmentPaymentStatus,
  AppointmentType,
  DocumentType,
  DocumentUploadedByWhomType,
  Gender,
  PaymentStatusType,
  ServiceRequestType,
} from './enums';
import { Dayjs } from 'dayjs';
import { Doctor } from './doctor.types';
import { RcFile } from 'antd/es/upload';
import { UploadFile } from 'antd';

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
type WithOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export interface DataResponse<T> {
  data: T[];
  total: number;
}

export interface Specialty {
  idFerSpeciality: string;
  milaSpecialityName: string;
  minServiceCost: number;
  doctorCount: number;
  minServeAge: number;
  maxServeAge: number;
}

export interface SpecialtiesArgs {
  serviceType: ServiceRequestType;
  practiceId?: string;
  region?: string;
  serveAge?: number;
}

export interface Profile {
  birthDay: string;
  email: string;
  gender: Gender;
  idProfile: string;
  itSelf: true;
  middleName: string;
  name: string;
  phone: string;
  surname: string;
  milaName: string;
  idPhoto: string;
  appointmentsCount: number;
  myClinicsCount: number;
  myDoctorsCount: number;
  isConfirmed: boolean;
  idPatient: string;
  oms: string;
  snils: string;
  lastUpdatedTimestamp: Date;
  isDataReceptionAllowed: boolean;
  isEsiaPhone: boolean;
  isEsiaEmail: boolean;
}

export interface ProfileRemoveReasons {
  code: string;
  text: string;
}

export interface ProfileRemoveArgs {
  data: {
    deleteReasonCodes: string[];
    otherReason: string | null;
  };
  userId: string;
}

export interface PatientSessionModel {
  sessionId: string;
  userId: string;
}

export interface CalendarRecord {
  doctor: {
    fullName: string;
    idMilaDoctor: string;
    serviceName: string;
    specializationName: string;
    idPhoto: string;
  };
  created: string;
  idAppointment: string;
  idExternalAppointment: string;
  isCancelled: boolean;
  medOrganization: {
    address: string;
    idMedOrganization: string;
    latitude: string;
    longitude: string;
    shortName: string;
  };
  patient: {
    fullName: string;
    idPatient: string;
    idPhoto: string;
  };
  paymentCost: string;
  paymentStatusName: string;
  paymentTypeName: string;
  review: { score: number; comment: string };
  serviceDeliveryType: ServiceRequestType;
  serviceDeliveryTypeName: string;
  tmkLink: string;
  paymentStatus?: AppointmentPaymentStatus;
  diagnosisMkb10Code: string;
  documents: MedcardDocument[];
  tips?: TipAppointment[];
  visitStartDate?: string;
  visitEndDate?: string;
  sharedLinkId?: string;
  isFreeCompleted?: boolean;
}

export interface FormattedCalendarRecords
  extends WithOptional<
    CalendarRecord,
    | 'isCancelled'
    | 'paymentTypeName'
    | 'paymentCost'
    | 'paymentStatusName'
    | 'tmkLink'
    | 'review'
    | 'idExternalAppointment'
    | 'diagnosisMkb10Code'
    | 'documents'
  > {
  isOnlineClinic?: boolean;
  isHomeClinic?: boolean;
  isPaidAppointment?: boolean;
  isHasConclusion?: boolean;
  isPastRecord?: boolean;
  medOrganizationPhones?: string[];
}

export interface DoctorReview {
  appointmentTimestamp: string;
  idMilaUserProfileAppointment: string;
  idPatientPhoto: string;
  patientShortName: string;
  reviewComment: string;
  reviewScore: number;
  reviewTimestamp: string;
  idMedOrganization: string;
  medOrganizationShortName: string;
  medOrganizationAddress: string;
  doctorSpecialtyName: string;
  doctorShortName: string;
  doctorPhotoStaticFileId: string;
  idMilaDoctor: string;
  idMilaUserProfile: string;
  idAppointmentReview: string;
}
export interface Reviews {
  reviewsCount?: number;
  reviewsAverageScore?: number;
  reviewsScoresDistribution?: Record<string, number>;
  canRate?: boolean;
  lastPastAppointmentToBeRatedId?: string;
  howToRateTipTitle?: string;
  howToRateTipHtmlBody?: string;
  reviews?: DoctorReview[];
}

export interface DateRelatedRecord {
  date: Date | string;
  recordsData: CalendarRecord[];
}

export interface RecordsWithDates {
  date: Dayjs;
  recordsData: CalendarRecord[];
}

export interface SearchArgs {
  query: string;
  region: string;
  idClinic?: string;
  serveAge?: number;
}

export interface SearchData {
  onSite: Specialty[];
  online: Specialty[];
  diagnostics: string[];
  atHome: string[];
  doctors: Doctor[];
  clinics: Clinic[];
  promotions: PromoInfo[];
}

export interface ClinicCommonInfo extends Clinic {
  doctorsCount: number;
  specializationsCount: number;
  onlineMinCost: number;
  onsiteMinCost: number;
  averageScore: number;
  reviewsCount: number;
  form: string;
  hasPromotions: boolean;
  servingAges: string[];
}

export interface ClinicAboutInfo {
  clinicAbout: ClinicAboutTabInfo;
  otherClinicsInNetwork: Clinic[];
  atHomeSpecializations: ClinicSpecialty[];
  inClinicSpecializations: ClinicSpecialty[];
  onlineSpecializations: ClinicSpecialty[];
}

export interface ClinicSpecialty {
  costDescription: string;
  specializationFerId: string;
  specializationName: string;
}

export interface Clinic {
  address: string;
  latitude: string;
  longitude: string;
  idMedOrganization: string;
  nearestSubwayDescription: string;
  phones: string[];
  shortName: string;
  workingHoursDescription: string;
  fullName: string;
  photoStaticFileIds: string[];
  logoStaticFileId: string;
  isConnectedToMila: boolean;
}

export interface AppointmentClinic extends Clinic {
  costFrom: number;
  doctorsCount: number;
}

export interface ClinicAboutTabInfo {
  aboutDescription?: string;
  documents?: Document[];
  howToGetThereDescription?: string;
  link?: string;
  parkingDescription?: string;
}

export interface DocumentsFormProps {
  docType: string;
  docDate: Date;
  search: string;
  isPublicDoc: boolean;
  upload: UploadFile[];
  docName?: string;
  organization?: string;
}

export interface ItemsTabs {
  label: string;
  key: string;
}

export interface FolloWUpDocument {
  followUpDocumentId: string;
  followUpDocumentName: string;
}

export interface UploadFollowUpDocumentArgs {
  file: RcFile;
  appointmentId: string;
  docType: string;
  docDate: Date;
  isPublicDoc: boolean;
}

export interface UploadProfileAvatar {
  file: RcFile;
  profileId: string;
}

export interface DictionaryItem {
  code: string;
  name: string;
}

export interface Document {
  mimeType: string;
  name: string;
  size: number;
  staticFileId: string;
}

export interface PromoInfo {
  idPromotion: string;
  title: string;
  subtitle: string;
  startTimestamp: string;
  endTimestamp: string;
  isPromotionInfinite: boolean;
  backgroundImageStaticFileId: string;
  description: string;
  buttonText: string;
  buttonLink: string;
  branches: Clinic[];
  networkLogoStaticFileId: string;
  displayType: DisplayType;
}

export enum DisplayType {
  Map = 0,
  List = 1,
  DontShow = 99
}

export interface PromoArgs {
  idPromotion?: string;
  idMilaDoctor?: string;
  idMedOrganization?: string;
  region?: string;
}

export interface WaitingListInfo {
  idWaitingListAppointment: string;
  created: string;
  comment: string;
  status: number;
  idMilaUserPatient: string;
  patientPhotoId: string;
  idMedOrganizationSpecialityService: string;
  idFedSpecialty: string;
  serviceName: string;
  doctor: Doctor;
  medOrganization: Clinic;
  patientFullName: string;
}

export interface DetachPhotoArgs {
  photoId: string;
  appointmentId: string;
}

export interface AttachPhotoArgs {
  file: Blob;
  appointmentId: string;
}

export interface CreateProfileArgs {
  name: string;
  middleName: string;
  surname: string;
  birthDay: string;
  gender: Gender;
  phone: string;
  email: string;
  address: string;
  snils: string;
  oms: string;
  getAgreement: boolean;
  milaName: string;
}

export interface CreateAppointmentArgs {
  patientId: string;
  idMedOrganization: string;
  idAppointment: string;
  idMilaDoctor: string;
  idFerSpeciality: string;
  doctorSpecialization: string;
  idSpecialityService: string;
  visitStartDate: string;
  visitEndDate: string;
  cost: string | number;
}

export interface CancelAppointmentArgs {
  isReschedule?: boolean;
  appointmentId: string;
}

export interface RescheduleAppointmentArgs {
  appointmentId: string;
  misTimeSlotId: string;
  startDate: string;
  endDate: string;
}
export interface ClinicArgs {
  promotionId?: string;
  region?: string;
  profileIds?: string[];
}

export interface MapState {
  zoom: number;
  center: [number, number];
}

export interface Consent {
  text: string;
  link: string;
  idMilaConsent: string;
  isActive: boolean;
  signed: string;
  revoked: string;
}

export interface NotificationEntity {
  isViewed: boolean;
  type: number;
  content: NotificationContent;
  created: string;
  idMilaUser: string;
  idMilaUserProfile: string;
  medOrganizationName: string;
  idNotification: string;
}

export interface NotificationsType extends DataResponse<NotificationEntity> {
  unreadCount: number;
}

export interface PaginationArgs {
  sort?: string;
  offset: number;
  count: number;
}

export interface ClinicsSearchArgs {
  search: string;
}

export interface NotificationContent {
  content: string;
  actionEntityId?: string;
}

export interface MedCarLastUpdate {
  latestAppointmentTimestamp: string;
}

export interface StatProfile {
  documentsTotalCount: number;
  firstDocumentTimestamp: Date;
  appointmentsCount: number;
  analysesAndExaminationsCount: number;
  diagnosesCount: number;
  hospitalizationsCount: number;
}
export interface MessageToSupport {
  subject: string;
  message: string;
  middleName: string;
  firstName: string;
  lastName: string;
  phone?: string;
  email: string;
  idProfile: string;
  idMedOrganization: string;
}

export interface ActiveProfileState {
  profileName: string;
  surname: string;
  idPhoto: string;
  idProfile: string;
  itSelf: boolean;
}

export interface MedOrganizationItem {
  idMedOrganization: string;
  shortName: string;
}

export interface ChildrenItem {
  id: string;
  snils: string;
  birthDate: string;
  firstName: string;
  lastName: string;
  middleName: string;
  gender: Gender;
}

export interface EsiaRequestArgs {
  idProfile: string;
  accessToken: string;
}
export interface MedcardCalendarYearRecord {
  year: number;
  appointments: MedcardCalendarMonthRecord[];
}

export interface MedcardCalendarMonthRecord {
  month: number;
  onSiteCount: number;
  tmkCount: number;
  atHomeCount: number;
  hospitalCount: number;
  dispensaryCount: number;
}

export interface MedcardDocument {
  appointmentType: AppointmentType;
  diagnosisMkb10Code: string;
  diagnosisName: string;
  doctorFullName: string;
  doctorSpecialtyName: string;
  documentDate: string;
  documentType: DocumentType;
  documentName: string;
  idExternalDocument: string;
  externalDocumentSource: ExternalDocumentSource;
  idMedOrganization: string;
  idMilaUserDocument: string;
  isPublic: boolean;
  medOrganizationName: string;
  profileId: string;
  uploadedByWhomType: DocumentUploadedByWhomType;
  serviceCode: string;
  files: MedcardDocumentAttachment[];
}

export interface MedcardDocumentAttachment {
  data: string;
  fileName: string;
  idDocumentFile: string;
  idExternalDocumentFile: string;
  mimeType: string;
  size: number;
}

export interface MedcardRecommedation {
  created: string;
  dosage: string | null;
  group: string | null;
  idClinic: string | null;
  mkb10Code: string | null;
  name: string;
  reason: string | null;
  typeName: string;
}

export interface EsiaLoginServiceResult {
  idToken: string;
  isSuccess: boolean;
  accessToken: string;
}

export interface ChildrenConsent {
  children: { id: string; isConsentSigned: boolean }[];
}

export interface MedcardDocumentsArgs {
  idProfile: string;
  idDocument: string;
  idFile?: string;
  files?: UploadFile[];
}

export interface MedcardUploadDocumentsArgs {
  idProfile: string;
  idDocument?: string;
  idMilaAppointment?: string;
  idExternalAppointment?: number;
  documentType: DocumentType;
  appointmentType: AppointmentType;
  documentDate: string;
  medOrganizationName: string;
  doctorFullName: string;
  doctorSpecialtyName: string;
  diagnosisCode: string;
  serviceCode: string;
  isPublic: boolean;
  upload: UploadFile[];
}

export interface MedcardUpdateExternalDocumentsArgs {
  externalDocumentId: string;
  isPublic: boolean;
  externalDocumentSource: ExternalDocumentSource;
}

export enum ExternalDocumentSource {
  IEMK = 1,
  ODLI = 2
}
export interface MedcardPreUploadDocumentsArgs {
  idProfile: string;
  files: UploadFile[];
}
export interface MedcardUploadSeveralDocumentsArgs {
  idProfile: string;
  document: {
    idMilaAppointment: string;
    idExternalAppointment: number;
    documentName: string;
    documentType: DocumentType;
    appointmentType: AppointmentType;
    documentDate: string;
    medOrganizationName: string;
    doctorFullName: string;
    doctorSpecialtyName: string;
    diagnosisCode: string;
    serviceCode: string;
    isPublic: boolean;
  };
  preUploadedFiles: string[];
}

export interface PreUploadResponse {
  fileId: string;
  fileName: string;
  expiresAt: string;
}

export type AppointmentDocumentsArgs = Omit<MedcardUploadDocumentsArgs, 'idProfile'>;

export interface DictionarySearchArgs {
  type: string;
  query: string;
}

export interface MedcardLink {
  created: string;
  expires: string;
  hideSmartAnalysisTab: boolean;
  idMilaSharedLink: string;
  idMilaUserProfile: string;
  isAlive: boolean;
  link: string;
  milaUserProfile: {
    idPhoto: string;
    idMilaUserProfile: string;
    milaName: string;
    firstName: string;
    lastName: string;
    middleName: string;
  };
  type: number;
  disabledLink: boolean;
}

export interface ShareMedcardArgs {
  idProfile: string;
  hideSmartAnalysisTab: boolean;
  type: number;
  idAppointment: string;
}

export interface PaymentsArgs extends PaginationArgs {
  type: string;
}

export interface Payment {
  id: string;
  status: PaymentStatusType;
  fromWhom: string;
  descriptionHint: string;
  description: string;
  date: string;
  amount: number;
}

export interface SharedMedcardInfo {
  idProfile: string;
  fio: string;
  idPhoto: string;
  birthday: string;
  gender: Gender;
  age: string;
  hideSmartAnalysisTab: boolean;
}

export interface MedcardNotification {
  idNotification: string;
  isViewed: boolean;
  type: number;
  content: NotificationContent;
  created: string;
  idMilaUser: string;
  idMilaUserProfile: string;
  medOrganizationName: string;
}

export interface AppointmentClinicsArgs {
  region: string;
  idFerSpecialty: string;
}

export interface AdditionalEmailArgs {
  email: string;
  appointmentId: string;
}

export interface AutocompleteOption {
  value: string;
  label: string;
  key: string;
}

export interface TmkChat {
  roomId: string;
  title: string;
}

export interface TmkUserInfo {
  sessionId: string;
  userId: string;
}

export interface AddWaitingListArgs {
  idMedOrganization: string;
  idMilaDoctor: string;
  idFerSpeciality: string;
  idSpecialityService: string;
  cost: string | number;
}

export interface CalendarRecordsArgs extends PaginationArgs {
  type: string;
  patientIds?: string[];
  medOrganizationId?: string;
}

export interface TipAppointment {
  tipTitle: string;
  tipBodyHtml: string;
}

export interface SetAppointment {
  appointmentId: string;
  anamnesisAllowOneTimeMedicalRecordAccessForDoctor: boolean;
  anamnesisComplaints: string;
  anamnesisDiagnosisFreeFormName: string;
  anamnesisDiagnosisDate: string;
}

export interface SignDocuments {
  processId: string;
  isWaiver: boolean;
  processContext: any;
}

export enum PharmDocumentStatus {
  Active = '0',
  Reviewed = '1',
  Archive = '2'
}

export interface UploadedFile {
  fileId: string;
  fileName: string;
  expiresAt: string;
}

export interface DocumentsState {
  fileUpload: {
    uploadedFiles: UploadedFile[];
  };
}

export enum MilaService {
  OnSite = 0,
  AtHome = 1,
  Online = 2,
  ChatWithClinic = 3,
  Discounts = 4,
  UploadDocuments = 5,
  SharedMedcard = 6,
  AddClinic = 7,
  AddProfile = 8
}

export interface WhiteLabelData {
  domain: string;
  milaPatientPortalPath: string;
  isServiceEnabled: boolean;
  idLogoFile: string;
  idIconFile: string;
  idFaviconFile: string;
  idPwaIconFile: string;
  headersColor: string;
  displayName: string;
  linksColor: string;
  copyrightTitle: string;
  copyrightDescription: string;
  medCardUpdatesColor: {
    textColor: string;
    backgroundColor: string;
  };
  nearestAppointmentColor: {
    textColor: string;
    backgroundColor: string;
  };
  mainButtonColor: {
    textColor: string;
    backgroundColor: string;
  };
  services: MilaService[];
  idMedCardAgreementFile: string;
  idNotificationAgreementFile: string;
  idPersonalDataAgreementFile: string;
  idOfferAgreementFile: string;
  authClientName: string;
  authUrl: string;
}

export enum SignedDocumentStatus {
  Undefined = 0,
  WaitDoctorSign = 1,
  Signed = 2,
  Refused = 3
}
