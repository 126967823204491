import {
  AddClinicText,
  ChildrenMedcardsText,
  ChildrenMedcardsTextWhiteLabel,
  ConfirmationAccountText,
} from './ModalContents/ModalContents';
import { AppointmentPaymentStatus } from 'src/common/types/enums';
import { Col, Image, Typography } from 'antd';
import { CustomModal } from '../../../common/components/CustomModal/CustomModal';
import { CustomSpinner } from '../../../common/components/CustomSpinner/CustomSpinner';
import { ImportantBlockModal } from './ImportantBlockModal/ImportantBlockModal';
import { ShareModal } from '../../MedCard/ShareModal/ShareModal';
import { SliderRow } from 'src/common/components/SliderRow/SliderRow';
import { UpdateCard } from './UpdateCard';
import {
  getAppointmentClinicName,
  getProfileById,
  getShortenClinicName,
  getWaitingListCardDateLabel,
  handleAddEsiaAccount,
  sortAppointmentsByDates,
} from '../../../common/utils';
import {
  useGetCalendarRecordsQuery,
  useGetMedcardNotificationsQuery, useGetPharmDocumentsQuery,
  useGetProfilesQuery,
  useUpdateNotificationStatusMutation,
} from '../../../common/redux/api/apiPatientSlice';
import {
  useGetWhiteLabelInfoQuery
} from '../../../common/redux/api/apiWhiteLabelSlice';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddClinic from 'src/assets/AddClinic.png';
import Bubble from 'src/assets/Bubble.svg';
import DarkGlasses from 'src/assets/DarkGlasses.png';
import Hand from 'src/assets/Hand.png';
import HandHearts from 'src/assets/HandHearts.png';
import HandPressing from 'src/assets/HandPressing.png';
import Smile from 'src/assets/Smile.png';
import MedcardDocs from 'src/assets/MedcardDocs.png';
import PharmNotification from 'src/assets/PharmNotification.png';
import YandexShare from 'src/common/components/YandexShare/YandexShare';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.module.scss';
import { MedcardDocumentLoadForm } from 'src/features/MedCard/MedcardDocumentLoadForm/MedcardDocumentLoadForm';
import {MilaService, PharmDocumentStatus} from '../../../common/types';
import { useAppSelector } from '../../../app/hooks';

export const ImportantBlock = () => {
  const { data: profiles, isLoading: isLoadingProfiles } = useGetProfilesQuery();
  const {
    data: medcardNotifications,
    isLoading: isLoadingMedcardNotifications,
    refetch: refetchMedcardNotifications,
  } = useGetMedcardNotificationsQuery();
  const [updateNotificationStatus] = useUpdateNotificationStatusMutation();
  // const { data: pharmNotifications } = useGetPharmDocumentsQuery({status: PharmDocumentStatus.Active, offset: 0, count: 100});
  const navigate = useNavigate();
  const [isInvitationModalOpen, setIsInvitationModalOpen] = useState(false);
  const [isAccountConfirmModalOpen, setIsAccountConfirmModalOpen] = useState(false);
  const [isChildrenMedcardModalOpen, setIsChildrenMedcrdModalOpen] = useState(false);
  const [isInvitationClinicModalOpen, setIsInvitationClinicModalOpen] = useState(false);
  const [isShareMedcardModalOpen, setIsShareMedcardModalOpen] = useState(false);
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const keycloakObj = useAppSelector((state) => state.keycloak.keycloakObj);
  
  const { data: whiteLabel } = useGetWhiteLabelInfoQuery('');
  const isWhiteLabelEnabled = useMemo(() => {
    return whiteLabel?.isServiceEnabled;
  }, [whiteLabel]);

  const whiteLabelCardUpdatesBakgroundColor = whiteLabel?.medCardUpdatesColor.backgroundColor;
  const whiteLabelCardUpdatesTextColor = whiteLabel?.medCardUpdatesColor.textColor;
  const whiteLabelAppointmentBackgroundColor = whiteLabel?.nearestAppointmentColor.backgroundColor;
  const whiteLabelAppointmentTextColor = whiteLabel?.nearestAppointmentColor.textColor;

  const handleOpenInvitationModal = () => {
    setIsInvitationModalOpen(true);
  };

  const handleCloseInvitationModal = () => {
    setIsInvitationModalOpen(false);
  };

  const handleOpenAccountConfirmModal = () => {
    setIsAccountConfirmModalOpen(true);
  };

  const handleCloseAccountConfirmModal = () => {
    setIsAccountConfirmModalOpen(false);
  };

  const handleOpenChildrenMedcardModal = () => {
    setIsChildrenMedcrdModalOpen(true);
  };

  const handleCloseChildrenMedcardModal = () => {
    setIsChildrenMedcrdModalOpen(false);
  };

  const handleOpenInvitationClinicModal = () => {
    setIsInvitationClinicModalOpen(!isInvitationClinicModalOpen);
  };

  const handleOpenShareMedcardModal = () => {
    setIsShareMedcardModalOpen(true);
  };

  const handleCloseShareMedcardModal = () => {
    setIsShareMedcardModalOpen(false);
  };

  const handleOpenMedcardDocsModal = () => {
    setIsDocumentModalOpen(true);
  };

  const handleOpenPharmNotificationModal = () => {
    navigate(`/docs`);
  };

  const handleCloseMedcardDocsModal = () => {
    setIsDocumentModalOpen(false);
  };

  const handleAppointmentClick = (appointmentId: string) => {
    navigate(`/records/appointment/${appointmentId}`);
  };

  const handleMedcardClick = (userId: string, idNotification: string) => {
    updateNotificationStatus(idNotification).then(async () => {
      await refetchMedcardNotifications();
      navigate(`/medcards/${userId}?activeTab=documents`);
    });
  };

  const handleAddProfile = () => {
    navigate('/settings/userProfile');
  };

  const isEsiaConnectedProfile = useMemo(() => profiles?.find((profile) => profile.itSelf)?.isConfirmed, [profiles]);
  const allProfiles = useMemo(() => profiles?.map((profile) => profile.idProfile) ?? [], [profiles]);
  const mainProfileId = useMemo(() => profiles?.find((profile) => profile.itSelf)?.idProfile, [profiles]);

  const { data: records, isLoading: isLoadingRecords } = useGetCalendarRecordsQuery(
    {
      type: 'upcoming',
      patientIds: allProfiles,
      offset: 0,
      count: 100,
    },
    { skip: !profiles?.length },
  );

  return isLoadingProfiles ? (
    <CustomSpinner />
  ) : (
    <SliderRow className={styles.ImportantRow} arrowsClass={styles.Arrows}>
      {!isEsiaConnectedProfile && (
        <Col
          className={`${styles.ImportantCol1} ${styles.ImportantCol} ${styles.ImportantColShortText}`}
          style={{ background: '#7F49DE' }}
          onClick={handleOpenAccountConfirmModal}
        >
          <Typography className={styles.Subtitle}>настройка профиля</Typography>
          <Typography className={styles.Title}>Подтвердите вашу учетную запись</Typography>
          <Typography className={styles.Text}>
            Перейдите в настройки и авторизуйтесь через Госуслуги, чтобы получать данные
          </Typography>
          <Image preview={false} src={Bubble} />
        </Col>
      )}

      {medcardNotifications?.map((notification, index) =>
        isLoadingMedcardNotifications ? (
          <CustomSpinner />
        ) : (
          <UpdateCard
            cardIndex={index}
            cardHeader={'МЕДКАРТА'}
            cardTitle={'Обновление в медкарте'}
            cardDescription={getWaitingListCardDateLabel(notification.created)}
            idPhoto={getProfileById(profiles!, notification.idMilaUserProfile)?.idPhoto}
            patientName={
              getProfileById(profiles!, notification.idMilaUserProfile)?.name
                ? `${getProfileById(profiles!, notification.idMilaUserProfile)?.surname} ${
                    getProfileById(profiles!, notification.idMilaUserProfile)?.name
                  }`
                : ''
            }
            medorganizationName={getShortenClinicName(notification.medOrganizationName)}
            patientId={notification.idMilaUserProfile}
            key={notification.idNotification}
            entityId={notification.content.actionEntityId}
            onClick={() => handleMedcardClick(notification.idMilaUserProfile!, notification.idNotification)}
            className={`${styles.MedcardUpdateCard} ${notification.isViewed && styles.HiddenBlock}  ${
              isWhiteLabelEnabled && styles.ImportantColUpdateMedcardWL
            }`}
            backgroundColor={isWhiteLabelEnabled ? whiteLabelCardUpdatesBakgroundColor : undefined}
            textColor={isWhiteLabelEnabled ? whiteLabelCardUpdatesTextColor : undefined}
            classNameCardIcon={styles.MedcardUpdateCardIcon}
          />
        ),
      )}

      {/*{pharmNotifications?.data?.map((document: any, key: any) => <UpdateCard*/}
      {/*    cardHeader={'КЛИНИЧЕСКИЕ ИССЛЕДОВАНИЯ'}*/}
      {/*    cardTitle={'Получен документ на подпись'}*/}
      {/*    cardDescription={document.name}*/}
      {/*    backgroundColor={"#830050"}*/}
      {/*    iconSrc={PharmNotification}*/}
      {/*    className={`${styles.ImportantColShortText} ${styles.ImportantColPharm}`}*/}
      {/*    onClick={handleOpenPharmNotificationModal}*/}
      {/*    key={key}*/}
      {/*/>)}*/}

      {sortAppointmentsByDates(records?.data)
        ?.filter(
          (record) => record.paymentStatus === AppointmentPaymentStatus.MoneyReserved || record.paymentStatus === null,
        )
        ?.map((record, index) =>
          isLoadingRecords ? (
            <CustomSpinner />
          ) : (
            <UpdateCard
              cardIndex={index}
              cardHeader={'БЛИЖАЙШИЙ ПРИЕМ'}
              cardTitle={record.doctor.specializationName}
              cardDescription={getWaitingListCardDateLabel(record.created)}
              patientName={record.patient.fullName}
              medorganizationName={getAppointmentClinicName(
                record.medOrganization.shortName,
                record.serviceDeliveryTypeName,
              )}
              doctorName={record.doctor.fullName}
              idPhoto={record.patient.idPhoto}
              patientId={record.patient.idPatient}
              key={record.idAppointment}
              entityId={record.idAppointment}
              onClick={() => handleAppointmentClick(record.idAppointment)}
              classNameCardIcon={styles.AppointmentUpdateIcon}
              classNameTitle={styles.ShortcutText}
              classNameDoctorName={styles.ShortcutText}
              backgroundColor={isWhiteLabelEnabled ? whiteLabelAppointmentBackgroundColor : undefined}
              textColor={isWhiteLabelEnabled ? whiteLabelAppointmentTextColor : undefined}
            />
          ),
        )}

      {(!isWhiteLabelEnabled || whiteLabel?.services?.includes(MilaService.AddClinic)) && (
        <UpdateCard
          cardHeader={'ПРИГЛАСИТЕ КЛИНИКУ'}
          cardTitle={'Как пригласить свою клинику'}
          cardDescription={'Подключите вашу клинику и получайте из нее мед. данные'}
          onClick={handleOpenInvitationModal}
          backgroundColor="#E79E44"
          iconSrc={Hand}
          className={styles.ImportantColShortText}
          classNameCardIcon={styles.AddClinicCardIcon}
        />
      )}
      <UpdateCard
        cardHeader={'МЕДКАРТА'}
        cardTitle={'Как делиться медкартой'}
        cardDescription={'Что важно знать, когда вы передаете ссылку'}
        backgroundColor="#87CB93"
        iconSrc={HandPressing}
        className={`${styles.ImportantColShortText} ${styles.ImportantColShareMedcard}`}
        onClick={handleOpenShareMedcardModal}
      />
      <UpdateCard
        cardHeader={'СВЯЗАННЫЕ ПРОФИЛИ'}
        cardTitle={'Медкарты на детей'}
        cardDescription={'Как добавить родственников в свой аккаунт'}
        backgroundColor="#4F489B"
        iconSrc={HandHearts}
        className={`${styles.ImportantColShortText} ${styles.ImportantColChildrenMedcard}`}
        onClick={handleOpenChildrenMedcardModal}
      />
      <UpdateCard
        cardHeader={'МЕДКАРТА'}
        cardTitle={'Загрузите свои документы в медкарту'}
        cardDescription={'Чтобы полная история всегда была под рукой'}
        backgroundColor="#7F49DE"
        iconSrc={MedcardDocs}
        className={`${styles.ImportantColShortText} ${styles.ImportantColChildrenMedcard}`}
        onClick={handleOpenMedcardDocsModal}
      />

      <CustomModal
        isOpen={isAccountConfirmModalOpen}
        className={styles.ModalContainer}
        onCancelClick={handleCloseAccountConfirmModal}
      >
        <ImportantBlockModal
          modalTitle="Подтвердите вашу учетную запись"
          boldText="Авторизуйтесь через Госуслуги, чтобы получать больше данных"
          modalContent={ConfirmationAccountText}
          buttonText="Подключить Госуслуги"
          buttonHandler={() => handleAddEsiaAccount(keycloakObj)}
          icon={DarkGlasses}
        />
      </CustomModal>

      <CustomModal
        isOpen={isInvitationModalOpen}
        className={styles.ModalContainer}
        onCancelClick={handleCloseInvitationModal}
      >
        <>
          <ImportantBlockModal
            modalTitle="Как пригласить свою клинику"
            boldText="И начать получать из нее свои медицинские документы"
            modalContent={AddClinicText}
            buttonText="Поделиться приглашением с клиникой"
            buttonHandler={handleOpenInvitationClinicModal}
            icon={AddClinic}
          />
          {isInvitationClinicModalOpen && (
            <YandexShare
              className={styles.Share}
              text="Здравствуйте! Прошу обеспечить доступ к моей медицинской карте в электронном виде в цифровом ассистенте пациента MILA. Для передачи данных рекомендую использовать платформу www.n3health.ru. По коду MILAPLUS сейчас действует скидка 50% на подключение."
            />
          )}
        </>
      </CustomModal>

      <CustomModal
        isOpen={isChildrenMedcardModalOpen}
        className={styles.ModalContainer}
        onCancelClick={handleCloseChildrenMedcardModal}
      >
        <ImportantBlockModal
          modalTitle="Медкарты для детей"
          boldText="Как добавить родственников в свой аккаунт"
          modalContent={isWhiteLabelEnabled ? ChildrenMedcardsTextWhiteLabel : ChildrenMedcardsText}
          buttonText="Добавить профиль"
          buttonHandler={handleAddProfile}
          icon={Smile}
        />
      </CustomModal>

      <ShareModal
        idProfile={mainProfileId!}
        className={styles.AddClinicModal}
        isModalOpen={isShareMedcardModalOpen}
        onCancelClick={handleCloseShareMedcardModal}
      />

      <CustomModal isOpen={isDocumentModalOpen} onCancelClick={handleCloseMedcardDocsModal}>
        <MedcardDocumentLoadForm
          onFinish={handleCloseMedcardDocsModal}
          idMedcardProfile={mainProfileId}
          isFromNotification
        />
      </CustomModal>
    </SliderRow>
  );
};
