import { Action, RecordsState } from 'src/features/Clinic/RecordsClinic/action.types';
import { CustomCardTabs } from 'src/common/components/CustomCardTabs/CustomCardTabs';
import { CustomSpinner } from '../../../common/components/CustomSpinner/CustomSpinner';
import { ItemsTabs } from 'src/common/types';
import { Layout, Row } from 'antd';
import { ProfileTabs } from 'src/common/components/ProfileTabs/ProfileTabs';
import { RecordsWrapper } from '../../../common/components/RecordsWrapper/RecordsWrapper';
import { WaitingList } from '../WaitingList/WaitingList';
import { itemsPerPage } from 'src/common/contants/constatns';
import { useEffect, useMemo, useReducer } from 'react';
import { useGetCalendarRecordsQuery, useGetProfilesQuery } from 'src/common/redux/api/apiPatientSlice';
import { useSearchParams } from 'react-router-dom';
import styles from './styles.module.scss';

const tabsItems: ItemsTabs[] = [
  {
    label: 'Предстоящие',
    key: 'upcoming',
  },
  {
    label: 'Прошедшие',
    key: 'past',
  },
  {
    label: 'Заявки',
    key: 'waiting',
  },
];

interface CalendarRecordsListProps {
  handleAddNewRecordClick?: () => void;
}

export const CalendarRecordsList = ({ handleAddNewRecordClick }: CalendarRecordsListProps) => {
  const [params] = useSearchParams();
  const { data: profiles, isLoading: isLoadingProfiles } = useGetProfilesQuery();
  const allProfiles = useMemo(() => profiles?.map((profile) => profile.idProfile) ?? [], [profiles]);

  const [state, dispatch] = useReducer(
    (state: RecordsState, action: Action) => {
      switch (action.type) {
        case 'AddRecords':
          return {
            ...state,
            records: [
              ...state.records.filter(
                (record) => !action.payload.some((newRecord) => record.idAppointment === newRecord.idAppointment),
              ),
              ...action.payload,
            ],
          };
        case 'NextPage':
          return { ...state, pageCount: state.pageCount + 1 };
        case 'RecordType':
          return { ...state, pageCount: 0, records: [], recordType: action.payload };
        case 'SelectedPerson':
          return { ...state, pageCount: 0, records: [], selectedPerson: action.payload };
        case 'Refetch':
          return { ...state, pageCount: 0, records: [] };
      }
    },
    { records: [], pageCount: 0, recordType: 'upcoming', selectedPerson: 'all' },
  );

  const {
    data: recordsData,
    isLoading,
    refetch,
  } = useGetCalendarRecordsQuery(
    {
      type: state.recordType,
      patientIds: state.selectedPerson === 'all' ? allProfiles : [state.selectedPerson],
      offset: itemsPerPage * state.pageCount,
      count: itemsPerPage,
      sort: 'created desc',
    },
    { skip: !(profiles?.length && profiles.length > 0 && state.selectedPerson) || state.recordType === 'waiting' },
  );

  useEffect(() => {
    if (recordsData?.data) {
      dispatch({ type: 'AddRecords', payload: recordsData.data });
    }
  }, [recordsData]);

  useEffect(() => {
    const profileId = params.get('profileId');
    const activeTab = params.get('activeTab');
    if (profileId) {
      dispatch({ type: 'SelectedPerson', payload: profileId });
    }
    if (activeTab) {
      dispatch({ type: 'RecordType', payload: activeTab });
    }
  }, [params]);

  const handleTabClick = (key: string) => {
    dispatch({ type: 'RecordType', payload: key });
  };

  const handlePersonTabClick = (key: string) => {
    dispatch({ type: 'SelectedPerson', payload: key });
  };

  const handleNextLoad = () => {
    dispatch({ type: 'NextPage' });
  };

  const refetchCalendarRecordsData = () => {
    dispatch({ type: 'Refetch' });
    refetch();
  };

  return (
    <Layout className={styles.CalendarRecordsListWrapper}>
      <Row>
        <CustomCardTabs items={tabsItems} activeKey={state.recordType} onChange={handleTabClick} />
      </Row>

      <Row>
        {isLoadingProfiles ? (
          <CustomSpinner />
        ) : (
          <ProfileTabs activeProfile={state.selectedPerson} onChange={handlePersonTabClick} />
        )}
      </Row>

      {state.recordType === 'waiting' ? (
        <WaitingList patientId={state.selectedPerson} />
      ) : (
        <RecordsWrapper
          records={state.records}
          handleAddNewRecordClick={handleAddNewRecordClick}
          selectedRecordType={state.recordType}
          isLoading={isLoading}
          refetchData={refetchCalendarRecordsData}
          handleNext={handleNextLoad}
          maxCount={recordsData?.total ?? 0}
        />
      )}
    </Layout>
  );
};
