import React, { useEffect } from 'react';

import styles from './styles.module.scss';
import {useGetOrderMedicinesTokenQuery} from "../../common/redux/api/apiPatientSlice";
import {useNavigate} from "react-router-dom";
import {Spin} from "antd";

export const OrderMedicines = () => {
  const navigate = useNavigate();
  const {data, isLoading} = useGetOrderMedicinesTokenQuery();
  const lockBody = () => {
    document.getElementsByTagName('html')[0].classList.add(styles.LockBody);
    document.getElementsByTagName('body')[0].classList.add(styles.LockBody);
  };

  useEffect(() => {
    lockBody();
  }, []);

  useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener('message', onIframeMessage, false);
    }
    return () => window.removeEventListener('message', onIframeMessage, false);
  }, []);
  
  const onIframeMessage = (data: any) => {
    if (data.data === 'close') {
      navigate('/home');
    }
  };

  return isLoading ? <Spin size={'large'} /> : data ? (
    <iframe
      title="widget"
      id="widget"
      src={`${window.__RUNTIME_CONFIG__.REACT_APP_ORDER_MEDICINES_URL}/?token=${data}`}
      className={styles.Widget}
    ></iframe>
  ) : <></>;
};
