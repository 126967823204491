import React, { useState } from 'react';

import styles from './styles.module.scss';
import { Row, Typography } from 'antd';

interface InfoBlockProps {
  title?: string;
  text?: string;
}

export const InfoBlock = ({ title = '', text = '' }: InfoBlockProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Row className={styles.Area}>
      <Typography onClick={() => setIsOpen((prev) => !prev)} className={`${styles.Title} ${isOpen ? styles.Open : ''}`}>
        {title}
      </Typography>
      <Typography className={`${styles.Text} ${isOpen ? styles.Open : ''}`}>{text}</Typography>
    </Row>
  );
};
