import { AgreementModal } from '../AgreementModal/AgreementModal';
import { Calendar, Clinics, Home, Notes, Settings, SingleDoc } from 'src/common/icons';
import { Col, Image, Row, Typography } from 'antd';
import { Footer } from 'antd/es/layout/layout';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useGetConsentsQuery, useGetProfilesQuery } from 'src/common/redux/api/apiPatientSlice';
import { useGetWhiteLabelInfoQuery } from 'src/common/redux/api/apiWhiteLabelSlice';
import MilaIcon from 'src/assets/MilaIcon.svg';
import React, { useMemo, useState } from 'react';
import styles from './styles.module.scss';

interface FooterProps {
  isShared?: boolean;
}

export const FooterRow = ({ isShared = false }: FooterProps) => {
  const location = useLocation();
  const path = useMemo(() => location.pathname.split('/')[1] ?? '', [location]);
  const navigate = useNavigate();
  const { data: profiles } = useGetProfilesQuery(undefined, { skip: isShared });
  const idProfile = useMemo(() => profiles?.find((profile) => profile.itSelf)?.idProfile || '', [profiles]);
  const { data: consents } = useGetConsentsQuery(idProfile, { skip: !idProfile });

  const [isAgreementModalOpen, setAgreementModalOpen] = useState(false);

  const handleTabClick = (tabName: string) => {
    navigate(`/${tabName}`);
  };

  const { data: whiteLabel } = useGetWhiteLabelInfoQuery('');
  const isWhiteLabelEnabled = useMemo(() => {
    return whiteLabel?.isServiceEnabled;
  }, [whiteLabel]);
  const icon = isWhiteLabelEnabled
    ? `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${whiteLabel?.idLogoFile}`
    : MilaIcon;

  const navMenuItems = useMemo(() => {
    const myProfile = profiles?.find((profile) => profile.itSelf);
    return [
      {
        label: 'Главная',
        key: 'home',
        icon: <Home />,
      },
      {
        label: 'Медкарты',
        key: `medcards/${myProfile?.idPatient}`,
        icon: <Notes />,
      },
      {
        label: 'Записи',
        key: 'records',
        icon: <Calendar />,
      },
      // {
      //  label: 'Документы',
      //  key: 'docs',
      //  icon: <SingleDoc />,
      // },
      {
        label: 'Каталог',
        key: 'catalog',
        icon: <Clinics />,
      },
      {
        label: 'Настройки',
        key: 'settings',
        icon: <Settings />,
      },
    ];
  }, [profiles]);

  const handleOpenConsentModal = (e: React.MouseEvent) => {
    e.preventDefault();
    setAgreementModalOpen(true);
  };

  const handleCloseModal = () => {
    setAgreementModalOpen(false);
  };

  return (
    <Footer className={styles.Footer}>
      <Row className={styles.DesktopFooterRow}>
        <Row className={styles.DesktopSubRow3}>
          <Image className={styles.MilaIcon} src={icon} preview={false} />
          <Col className={styles.DesktopFooterCol}>
            <Row className={styles.DesktopSubRow}>
              <Typography className={styles.DesktopText}>{`${
                isWhiteLabelEnabled ? whiteLabel?.copyrightTitle : ' Mila © 2023'
              }`}</Typography>
              {!isWhiteLabelEnabled && (
                <Typography className={styles.DesktopText}>Работает на платформе N3.Health</Typography>
              )}
            </Row>
            <Row>
              <Typography className={styles.DesktopTextSmall}>
                {`${
                  isWhiteLabelEnabled
                    ? whiteLabel?.copyrightDescription
                    : 'Разработчик ООО «ЭлНетМед» является официальным оператором иной информационной системы, уполномоченным Минздравом России для размещения сведений об оказанной медицинской помощи в ЕГИСЗ МЗ РФ.'
                }`}
                {!isWhiteLabelEnabled && (
                  <Typography.Link href="https://n3health.ru/" target="_blank" className={styles.DesktopLinkSmall}>
                    Подробнее
                  </Typography.Link>
                )}
              </Typography>
            </Row>
          </Col>
        </Row>

        <Row className={styles.DesktopSubRow2}>
          <Col className={styles.DesktopFooterCol2}>
            <Link to="/consent" className={styles.DesktopLink}>
              {isWhiteLabelEnabled ? 'Согласия на доступ к данным' : 'Доступ к данным'}
            </Link>
            <Link to="/help" className={styles.DesktopLink}>
              {isWhiteLabelEnabled ? 'Техническая поддержка' : 'Помощь'}
            </Link>
          </Col>

          {!isWhiteLabelEnabled && (
            <Col className={styles.DesktopFooterCol2}>
              <Link
                to="https://drive.google.com/file/d/1aehL_w5qgA_Rw797xa6-7y8td_UXa0yg/view"
                target="_blank"
                className={styles.DesktopLink}
              >
                Политика конфиденциальности
              </Link>
              <Link to="" target="_blank" className={styles.DesktopLink} onClick={handleOpenConsentModal}>
                Согласие на обработку персональных данных
              </Link>
            </Col>
          )}
        </Row>
        <AgreementModal
          consentId={
            consents?.find((consent) => consent.text === 'Согласие на обработку персональных данных')?.idMilaConsent ??
            ''
          }
          isModalOpen={isAgreementModalOpen}
          handleCloseModal={handleCloseModal}
        />
      </Row>
      {path !== 'shared' && path !== 'onboarding' && (
        <Row className={styles.MobileFooterRow}>
          {navMenuItems.map((item) => (
            <Col
              key={item.key}
              className={`${styles.FooterCol} ${item.key.includes(path) && styles.ActiveCol}`}
              onClick={() => handleTabClick(item.key)}
            >
              {item.icon}
              <Typography className={styles.FooterLabel}>{item.label}</Typography>
            </Col>
          ))}
        </Row>
      )}
    </Footer>
  );
};
